import React from "react";
import Layout from "../../components/layout/Layout";
import { useStaticQuery, graphql } from "gatsby";
import ImageSection from "../../components/ui/sections/ImageSection";
import TagsSection from "../../components/ui/sections/TagsSection";

const SeepointPage = () => {
  const query = useStaticQuery(graphql`
    query {
      articleImg: file(relativePath: { eq: "portfolio/seepoint.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
    }
  `);

  const tags = [
    "Wordpress",
    "architektura informacji",
    "optymalizacja SEO dwóch wersji językowych",
    "nowoczesny projekt graficzny",
    "intuicyjny interfejs użytkownika",
    "system zarządzania treścią",
  ];

  return (
    <Layout pageTitle="seepoint.pl">
      <main className="container mx-auto px-5">
        <ImageSection
          subtitle="Wordpress CMS"
          title="Wyjście globalne drukarni wielkoformatowej"
          image={query.articleImg.childImageSharp.gatsbyImageData}
        >
          <p className="text-gray-700 text-base mb-4">
            Drukarnia materiałów reklamowych otrzymała od nas nową firmową
            stronę www. Strona została przygotowana w dwóch językach. Zadbaliśmy
            o przeniesienie z poprzedniej wersji strony wszystkich podstron,
            produktów i wpisów z zachowaniem wypracowanych wcześniej zasad SEO.
          </p>
          <p className="text-gray-700 text-base mb-4">
            W projekcie skupiliśmy się aby dokładnie wypełnić wszystkie meta
            tagi wymagane przez Google dla każdej podstrony w obu językach.
            Ułatwiliśmy również edycję podstron produktowych dla edytorów
            strony.
          </p>
        </ImageSection>
        <TagsSection title="Projekt w pigułce" tags={tags} />
      </main>
    </Layout>
  );
};

export default SeepointPage;
